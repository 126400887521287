<template>
    <div class="pt-3">

        <a href="#" data-toggle="modal"  data-target="#newcompanyModal" @click.prevent="" class="btn btn-success">{{ $t('vytvorit-firmu')}}</a>

        <modal name="usersblok" :height="'auto'" :adaptive="true">
            <div class="modal-users">
                <div class="h4">Vyhledat uzivatele a priradit do {{current_acc.name}}</div>
                e-mail: <input type="text" v-model="newemail"/>
                <button class="btn btn-outline-success" @click="priraditEmail">Priradit</button>
            </div>
        </modal>

        <!-- Activity Modal -->
        <div class="modal fade" id="newcompanyModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document" style="max-width:600px;">
                <div class="modal-content">
                    <div class="modal-header py-2">
                        <h6 class="my-1">{{$t('team.new-account-header')}}</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Zavřít">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="">

                            <div v-if="!accounts || accounts.length==0">
                                <div class="d-flex ">
                                    <input type="text" v-model="accountName" class="form-control " :placeholder="$t('team.form.company-name')">
                                    <button class='btn btn-success' @click="createAccount" style="width: 190px;">{{ $t('vytvorit-firmu')}}</button>
                                </div>
                                <small
                                        class="form-text text-danger mt-0"
                                        v-if="$v.accountName.$dirty && !$v.accountName.required">{{$t('error.nutno-vyplnit')}}</small>
                                <small
                                        class="form-text text-danger mt-0"
                                        v-if="$v.accountName.$dirty && !$v.accountName.minLength">{{$t('team.new.minimumlength')}}</small>
                                {{msg}}
                            </div>
                            <div>
                                <div class="mt-3" v-if="accounts.length>0">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{$t('team.your-company')}}</th>
                                            <th>{{$t('team.status')}}</th>
                                        </tr>
                                        <tr v-for="a in accounts" :key="a.id">

                                            <td>{{a.name}}</td>
                                            <td>{{statusMessage(a)}}</td>
                                        </tr>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>






    </div>
</template>

<script>

    const qs = require("qs");
    const axios = require("axios");
    axios.defaults.withCredentials = true
    import { required, minLength } from "vuelidate/lib/validators";

    export default {
        name: "Account",
        validations: {
            accountName: {
                required ,
                minLength: minLength(2)
            }
        },

        data(){
            return {
                accounts:[],
                accountName:'',
                msg:"",
                current_acc:'',
                newemail:''


            }
        },
        mounted(){
            this.refreshAccounts()
        },

        computed: {
            me() {
                if (this.$store.getters.me)
                    return this.$store.getters.me;
                else
                    return {}
            }
        },
        methods:{

            statusMessage(a){

                if (a.status) {
                    return this.$t('team.status.' + a.status)
                }
                /* if (a.status=='waiting') return "Do firmy CAG byla odeslána žádost. Nyní čeká na schválení."
                 if (a.status=='accepted') return "Potvrzeno"
                 if (a.status=='disabled') return "Zablokováno"*/

                return ""

            },

            pridatUsera(acc) {
                this.current_acc = acc
                this.$modal.show("usersblok");
            },

            priraditEmail(){
                console.log('pridam do ', this.current_acc.id, this.current_acc.name, this.newemail)

            },

            createAccount(){
                this.$v.accountName.$touch();
                if (this.$v.accountName.$invalid) return
                axios
                    .post(
                        process.env.VUE_APP_API_ROOT_URL + "/account?t="+ new Date().getTime(),
                        qs.stringify({ name: this.accountName })
                    )
                    .then(result => {
                        //console.log('reg', result.status, result.data)
                        if (result.status == 201 && result.data.id > 0) {
                            this.msg = "Firma vytvorena";
                            this.refreshAccounts()
                        }else{
                            if (result.data.errors){
                                this.msg = result.data.errors
                            }
                        }
                    })
                    .catch(err => {
                        this.msg = this.expandErrors(err.response.data.errors)

                    });

            },
            expandErrors(err){
                let m=''
                for(let k in err){
                    m+= k+ " "+ err[k]
                }
                return m
            },
            refreshAccounts(){
                axios.get(
                    process.env.VUE_APP_API_ROOT_URL + "/accounts?t="+ new Date().getTime() )
                    .then(result => {
                        this.accounts = result.data

                    })


            }



        }

    }
</script>

<style scoped>

</style>
