<template>
  <div>
    <div id="team-list">
      <div>

        <div class="options w-100 py-2">
          <div class="d-inline-block text-center">
            <router-link :to="'/main/motors/'">
              <div class="icon mx-2">
                <span class="ukazatel-alertu" v-if="pocetVystrah>0">{{pocetVystrah}}</span>
                <div class="active-line"></div>
                <img src="/img/moje_karty.png" alt />
              </div>
              <div class="mt-1">{{$t('option.moje-karty')}}</div>
            </router-link>
          </div>

          <div class="d-inline-block text-center">
            <router-link to="/groups" class="router-link-exact-active active-line">
              <div class="icon mx-2">

                <div class="active-line"></div>
                <img src="/img/group-50.png" alt />
              </div>
              <div class="mt-1">{{$t('menu.team')}}</div>
            </router-link>
          </div>
        </div>




        <div class="mt-2 mx-2">
          {{ $t('team.firemni-skupina') }} {{members.length}} {{ $t('team.clenu') }}
        </div>
        <h3 class="mt-1 ml-2"> {{company}}</h3>

        <div class="options w-100 py-2" >

        </div>

        <div class="d-flex pt-2  submenu2">
          <a @click.prevent="tab=4; load_teammotors()" href="#" :class="'mx-2 '+(tab==4 ? 'active':'')">
            {{$t('groups.subnemu.prehled')}}
          </a>
          <a @click.prevent="tab=1" href="#" :class="'mx-2 '+(tab==1 ? 'active':'')">
            {{$t('option.clenove-teamu')}}
          </a>
          <a v-if="my_role=='admin'" href="#" @click.prevent="tab=2" :class="'mx-2 '+(tab==2 ? 'active':'')">
            {{$t('option.pridat-clena')}}
          </a>
        </div>
      </div>

      <!-------------------------------------------------------------------------------------------------------------->
      <div class="list w-100 py-2 px-1 mt-3" v-if="tab==1 && mamUcet">

       <!-- <h4 class="h6 pt-1">{{$t('team.members')}}</h4>-->
        <TeamMember v-for="mm in members" :key="mm.id" :m="mm" :my_role="my_role" :company="company" :account_id="account_id"></TeamMember>

      </div>

      <div v-if="tab==4 && mamUcet" id="motor-list">

        <div class="list  w-100 py-2 px-1 mt-3">
<!--          <h4 class="h6 pt-1">{{$t('team.motors-head')}}</h4>
          -->
          <MotorItem v-for="motor in motors" :key="motor.id" :motor="motor"></MotorItem>
        </div>
      </div>


      <div class="list w-100 py-2 px-1" v-if="tab==2 && mamUcet">
        <h4 class="h6 pt-1">{{$t('team.add-new-user')}}</h4>
        <div class="row">
          <div class="col col-8 col-lg-5">
            <p>{{$t('team.add-new-user-info')}}</p>
            <div class="form-group mb-0 ">
              <label for="inputEmail">{{$t('team.insert-email')}}</label>
              <input id="inputEmail" type="text" class="form-control form-control-sm"
                     v-model.trim="$v.newemail.$model"
                     @input="delayTouch($v.newemail)"/>
            </div>
            <small
                class="form-text text-muted invisible mt-0"
                v-if="!$v.newemail.$dirty || !$v.newemail.$invalid"
            >{{$t('team.insert-email')}}</small>
            <small
                class="form-text text-danger mt-0"
                v-if="$v.newemail.$dirty && !$v.newemail.required"
            >Toto pole je nutné vyplnit</small>
            <small
                class="form-text text-danger mt-0"
                v-if="$v.newemail.$dirty && !$v.newemail.email"
            >Takto e-mailová adresa nevypadá</small>





            <button class="btn btn-success mb-2" @click="priraditEmail($v)">{{$t('odeslat')}}</button>
            <div :class="invResultClass">{{invitResult}}</div>

          </div>
        </div>





        <div class="d-inline-block member m-1"  v-if="kon_user && kon_user.id">
          <div class="single p-1">
            <div class="media">
              <div class="icon flex-fill align-self-center text-success">
                <img class="mx-2" src="/img/clen-teamu.png" alt />
              </div>
            </div>

            <div class="detail mt-3 mb-2">
              <div class="row m-0">
                <div class="col col-12">
                  <div class="row mb-1">
                    <div class="col col-5 font-weight-bold pr-1">Jméno:</div>
                    <div class="col col-7 font-weight-bold bg-white text-break pl-1">{{kon_user.name}}</div>
                  </div>
                  <div class="row mb-1" v-if="kon_user.default_company">
                    <div class="col col-5 font-weight-bold pr-1">Společnost:</div>
                    <div class="col col-7 bg-white text-break pl-1">{{kon_user.default_company.name}}</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col col-5 font-weight-bold pr-1">Email:</div>
                    <div class="col col-7 bg-white text-break pl-1">{{kon_user.email}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>

      <div class="list w-100 py-2 mt-3" v-if="tab==3 || tab==1 && mamUcet">
        <h4 class="h6 px-1 pt-1">{{$t('team.nadpis.pozvani')}}</h4>


        <table class="table table-striped">
          <tr>
            <th>e-mail</th>
            <th>{{$t('team.table.user')}}</th>
            <th></th>

            <th>{{$t('team.table.vytvoreno')}}</th>
            <th>{{$t('team.table.potvrzeno')}}</th>

          </tr>
          <tr v-for="i in invitations" :key="`inv${i.id}`">
            <td>{{i.to_user ? i.to_user.email : i.email}}</td>
            <td>{{i.to_user ? $t('team.table.mistni') : $t('team.table.nebyl_reg') }}</td>
            <td>{{i.to_user ? i.to_user.name: ''}}</td>
            <td>{{df(i.created_at)}}</td>
            <td>{{i.accepted_at ? df(i.accepted_at) : $t('no')}}</td>
          </tr>

        </table>

      </div>
    </div>



  <div v-if="!mamUcet">
    <div class="alert alert-warning mt-5">{{$t('team.musite-mit-firmu')}}</div>
    <Account></Account>
  </div>
  </div>
</template>

<script>

/* v této záložce uvidím všechny členy, kteří jsou pod firmou přidáni a vidím tedy jejich motory.
6-Pokud se rozhodnu přidat nového člena teamu, musím mu poslat žádost, kterou on na telefonu dostane jako upozornění a potvrdí přijetí pozvánky.
*/
import TeamMember from "@/views/company/TeamMember";
const qs = require("qs");
const axios = require("axios");
axios.defaults.withCredentials = true

import { required, email } from "vuelidate/lib/validators";
const touchMap = new WeakMap();

import MotorItem from "../components/MotorItem";
import Account from "../components/Account";

const moment = require('moment');
require('moment/locale/cs');
moment.locale('cs');
export default {
  name: "Team",
  components: {TeamMember, MotorItem, Account},
  data(){
    return {
      pocetVystrah:0,
      members: [{
        'id': 1,
        'name': 'Karel Novák',
        'company': 'Makači s.r.o.',
        'email': "em@email.cz"
      },
        {
          'id': 2,
          'name': 'Karel Novák',
          'company': 'Makači s.r.o.',
          'email': "em@email.cz"
        },
      ],//[],
      company:'',
      newemail:'',
      account_id:null,
      tab:4,
      invitResult:'',
      kon_user:{},
      invitations:[],
      motors:[],
      invResultClass:'',
      my_role:0,
    }
  },
  validations: {
    newemail: {
      required,
      email
    }
  },
  computed: {
    me() {
      if (this.$store.getters.me){
        console.log('teammme', this.$store.getters.me.id)
        return this.$store.getters.me;
      }
      else
        return {}
    },
    mamUcet(){
      return true
      //return this.me.account_type == 'company'
    }
  },
  watch:{
    tab(val){
      if (val==3){
        this.pozvanky()
      }
    }
  },
  methods:{
    df: function (time, format) {
      var a = moment(time);
      moment.locale(this.$i18n.locale);
      return a.format(format ? format : "LLL")
    },


    load_teammotors(){
      //let account_id=this.$store.getters.me && this.$store.getters.me.default_account_id ? this.$store.getters.me.default_account_id : 'default'

      let account_id = this.account_id


      if (account_id) {
        axios.get(`${process.env.VUE_APP_API_ROOT_URL}/account/${account_id}/motors?t=${new Date().getTime()}`).then(result => {
          //console.log('res', result.data)
          if (result.status==200 || result.status==304){
            this.motors = result.data

          }
        })
      }
    },

    loadTeam(){
      let account_id = this.$route.params.id
      if (account_id) {
        axios.get(process.env.VUE_APP_API_ROOT_URL + "/account/" + account_id + ".json?t="+new Date().getTime()).then(result => {
          if (result.status==200){
            this.members = result.data.group.members
            this.company = result.data.group.name
            this.group = result.data.group
            this.account_id = result.data.group.id
            this.my_role = result.data.current_au.role

            for(var i=0; i< this.members.length; i++) {
              this.members[i].visiblesel=false
              this.members[i].account_role = this.transform_accountRole(this.members[i].account_role)
            }

            this.load_teammotors()
          }
        })
      }
    },

    transform_accountRole(r){
      if (parseInt(r)==0) return 'nonconfirmed'
      if (parseInt(r)==1) return 'technican'
      if (parseInt(r)==2) return 'manager'
      if (parseInt(r)==3) return 'admin'
    },

    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    priraditEmail($v){
      $v.newemail.$touch();
      if (!$v.newemail.$invalid) {
        var postdata= qs.stringify({account_id: this.account_id, email: this.newemail})
        this.invitResult=""
        this.invResultClass=''
        axios.post(process.env.VUE_APP_API_ROOT_URL + "/account/connectuser", postdata).then(result => {
          var d= result.data
          if (d.message){
            this.invitResult = this.$t('team.'+d.message)
          }
          if (d.status =="invitation"){
            this.invitResult = this.$t('team.pozvan-emailem')
          }
          if (d.status == 'connected'){
            this.kon_user=d.user
            this.invitResult = this.$t('team.pozvan-emailem')
          }
          if (d.status=="ok2"){
            this.invitResult = this.$t('team.connected_before')
          }
          if (d.status=='local_invitation'){
            this.kon_user=d.user
            this.invResultClass='alert alert-success'
            this.invitResult = this.$t('team.local_invitation')
          }
          if (d.status == 'not_found'){
            this.invitResult = this.$t('team.not_found')
            this.invResultClass='alert alert-danger'
          }
        })
      }
    },

    pozvanky() {
      axios.get(process.env.VUE_APP_API_ROOT_URL + "/account/invitations.json").then(result => {
        if (result.status == 200) {
          this.invitations = result.data
        }
      });
    }


  },
  mounted(){
    this.loadTeam()



    this.$nextTick(function () {

      if (this.me.id<0)
        this.$router.push({ path: "/" });
    })
  }
}
</script>

<style scoped>
img.tuzka{
  margin-left:15px;
  cursor: pointer;
}
</style>
